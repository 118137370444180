import React from "react";
import ReactDOM from "react-dom/client";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {QueryClient, QueryClientProvider} from 'react-query';
import '../i18n';
import CssBaseline from '@mui/material/CssBaseline';
import SignIn from "./SignIn.jsx";
import ToggleColorMode from "../context/ColorModeContext.jsx";

const queryClient = new QueryClient();


ReactDOM.createRoot(document.getElementById("root")).render(
    <>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ToggleColorMode theme={{}}>
                    <CssBaseline/>
                    <SignIn/>
                </ToggleColorMode>
            </QueryClientProvider>
        </React.StrictMode>
    </>
);
